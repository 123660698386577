.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fa-edit{
  color:lightblue;
}

.fa-edit:hover{
  color:deepskyblue;
}

.fa-print{
  color:lime
}

.fa-print:hover{
  color:limegreen;
}

.fa-trash{
  color:red
}

.fa-trash:hover{
  color:darkred;
}

.fa-comment{
   color:dodgerblue;
}

.fa-comment:hover{
  color:blue
}

.fa-user{
  color:rosybrown;
}

.fa-user:hover{
  color:saddlebrown;
}


/* Slider */

.slide-container {
  width: 100%;
  margin: auto;
  margin-top: 120px;
}

h3 {
  text-align: center; }

.each-slide > div {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
  box-shadow: 0px 0px 7.2px 2.8px rgba(0, 0, 0, 0.35);
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
  width: 100%;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.card1{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.center1{
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.card2{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.link-f{
  display: flex;
  justify-content: center;
}

.link-for{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.point_ranking{
  width: 100%;
}

.form-w{
  width: 60% !important;
}

.login-form {
  width: auto;
}

.heading1{
  text-align: center;
}

@media(max-width: 1100px){
  .card2{
    width: 70%;
  }

  .form-w{
    width: 70% !important;
  }
}

@media(max-width: 900px){
  .card2{
    width: 80%;
  }

  .form-w{
    width: 80% !important;
  }
}

@media(max-width: 600px){
  .card1{
    width: 95%;
    margin-left: 2%;
  }

  .card2{
    width: 90%;
  }

  .form-w{
    width: 90% !important;
  }

  .slide-container{
    margin-top: 100px;
  }
}